import { ItemList } from "./ItemList";
import { sendRequest } from "../functions/sendRequest";
/**
 * FavoriteList module
 * @module modules/FavoriteList
 * @todo переделать инициализацию this.items
 */
/**
 * Класс списка избранных товаров
 */
export class FavoriteList extends ItemList {
  static api = {
    url: "/api/v1/favorites/",
    add: { method: "POST" },
    remove: { method: "DELETE" },
  };

  constructor({ items }: { items: string[] | null }) {
    super({
      items,
      type: "favorite",
      limit: 5,
      counterClass: "favorite-count",
    });
  }

  /**
   * Добавляет новый ID товара в список
   * @throws ItemList.ERROR_ALREADY_LISTED
   * @param  {string}  id            - ID товара
   * @param  {boolean} dispatchEvent - Генерировать событие
   * @return {boolean}
   */
  add(id: string, dispatchEvent: boolean = true): boolean {
    if (typeof id !== "string") {
      throw new Error("Invalid 'id' parameter");
    }
    if (typeof dispatchEvent !== "boolean") {
      throw new Error("Invalid 'dispatchEvent' parameter");
    }

    if (this.hasId(id)) {
      throw new Error(ItemList.ERROR_ALREADY_LISTED);
    }
    if (this.size() >= this.getLimit()) {
      return false;
    }

    this.items.set(id, null);

    if (dispatchEvent) {
      document.dispatchEvent(
        new CustomEvent(ItemList.EVENT_AFTER_UPDATE, {
          detail: { list: this },
        })
      );

      this.doAdd(id);
    }
    return true;
  }

  /**
   * Удаляет ID товара из списка Избранного
   * @param  {string}  id - ID товара
   * @return {boolean}
   */
  remove(id: string): boolean {
    if (typeof id !== "string") throw new Error("Invalid 'id' parameter");

    let result = this.items.delete(id);
    if (!result) throw new Error(ItemList.ERROR_ITEM_NOT_FOUND);

    this.doRemove(id);

    document.dispatchEvent(
      new CustomEvent(ItemList.EVENT_AFTER_UPDATE, { detail: { list: this } })
    );
    return result;
  }

  /**
   * @todo
   * - обработать ошибки сервера: откат/недопускать несовпадения списков клиент-сервер
   * @param  {number} id Уникальный идентификатор товара
   */
  protected async doAdd(id: string) {
    if (typeof id !== "string") throw new Error("Invalid 'id' parameter");
    const result = await sendRequest(FavoriteList.api.url, {
      method: "POST",
      json: { id },
    });

    if (result === null) {
      // @todo ??? что дальше
    }
  }

  protected async doRemove(id: string) {
    if (typeof id !== "string") throw new Error("Invalid 'id' parameter");
    const result = await sendRequest(FavoriteList.api.url, {
      method: "DELETE",
      searchParams: { id },
    });

    if (result === null) {
      // @todo ??? что дальше
    }
  }

  /**
   * Переключает состояние кнопки "в Корзину" в зависимости от вхождения
   * или отсутствия товара в списке. Если второй параметр не передан,
   * то меняет состояние кнопки на противоположное.
   * @todo
   * - static или вынести в функции
   * @param  {HTMLElement} card   Карточка товара
   * @param  {boolean}     status Указывает есть ли товар в списке
   */
  toggleButton(card: HTMLElement, status: boolean) {
    let force;
    const btn = card.querySelector('button[is="button-favorite"]');
    if (!btn) return;

    const current = btn.hasAttribute("data-listed");
    // console.log('current', current)
    if (status === undefined) {
      btn.toggleAttribute("data-listed");
    } else if (status && !current) {
      btn.setAttribute("data-listed", "");
    } else if (!status && current) {
      btn.removeAttribute("data-listed");
    }
    //let force = !current;
    if (status !== undefined) {
      force = !status;
    }
    btn.classList.toggle("btn_favorite_add", force);
    force = status;
    btn.classList.toggle("btn_favorite_in", force);
  }
}
