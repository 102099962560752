import { Picture } from "../layers/picture.js";
export class ProductCard {
  constructor(product, labels, polishes, classes = "") {
    this._item = product;
    this._picture = "";
    this._classes = classes;
    this._lazy = null;
    this._polishes = polishes;
  }

  start() {
    return `<div class="${this._classes}">`;
  }

  renderPicture() {
    let picture = new Picture(this._item, [200, 400]);
    this._picture = picture.render();
  }
  renderPolishes() {
    let content = '<div class="polishes polishes_classic">';
    //console.log(this._polishes);
    for (let key in this._polishes) {
      content += `<span class="${key}" data-toggle="tooltip" data-placement="top" title="${this._polishes[key]}"></span>`;
    }
    content += "</div>";
    this._polishes = content;
  }

  content() {
    let content = "";
    ///let rate = this._item.printRating();
    content += `
<div class="card product-card product-info" id="${this._item.getId()}"
data-id="${this._item.getId()}"
data-sku="${this._item.getId()}"
data-title="${this._item.getName()}"
data-detail='${JSON.stringify(this._item)}'
>
<a href="${this._item.url}">
<div class="img-wrap ratio-container ratio-container-1-1 product-img">
${this._picture}
<!--<img class="card-img-top img-responsive lazyload" data-src="/public/images/catalog/${this._item.getImage(
      400
    )}-400.jpg" alt=""/>-->
</div>
</a>
<div class="card-body">
<div class="btn_group__favorite_cart">
  <button is="button-favorite" class="btn btn-raised btn-floating btn_favorite btn_favorite_add"></button>
  <button is="button-cart" class="btn btn-raised btn-floating btn_cart btn_cart_add"></button>
</div>
<a href="${this._item.url}">
<h5 class="card-title threepoint">${this._item.getName()}</h5>
</a>
<p class="product-price">${currencyFormatter.format(
      this._item.getPrice()
    )} грн</p>
${this._polishes}
</div>
</div>`;
    return content;
  }

  end() {
    return "</div>";
  }

  render() {
    this.renderPicture();
    this.renderPolishes();
    return this.start() + this.content() + this.end();
  }
}
