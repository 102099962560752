export class Picture {
  constructor(product, sizes) {
    this._exts = ['webp', 'jpg'];
    this._sizes = sizes;
    this._product = product;
  }

  start() {
    return '<picture>';
  }

  content() {
    let image = "",
      path = "",
      src = [],
      srcset = "",
      sources = [];
    this._exts.forEach((ext) => {
      src = [];
      this._sizes.forEach((size) => {
        path = this._product.getImage(size, ext) + ` ${size}w`;
        src.push(path);
      });
      srcset = src.join(', ');
      sources.push(`<source data-srcset="${srcset}" type="image/${ext}">`);
    });
    image = `<img class="responsive-img lazyautosizes lazyload" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
title="${this._product.getName()}"
alt="${this._product.getName()}"
data-sizes="auto"/>`;
    sources.push(image);
    return sources.join(' ');
  }

  end() {
    return "</picture>";
  }

  render() {
    return this.start() + this.content() + this.end();
  }

}
