/**
 * Product module
 * @module modules/Product
 */

/**
 * Класс товара
 */
export class Product {
  protected id: string;
  protected sku?: string;
  protected cartId?: number;
  protected name?: string;
  protected categoryId?: string;
  protected brand?: string;
  protected image?: string;
  protected colorId?: number;
  protected patina?: boolean;
  protected patinaColorId?: number;
  protected price?: number;
  protected priceBase?: number;
  protected quantity?: number;
  protected url?: string;
  protected variationId?: string;
  protected rating?: number;

  constructor(config: any) {
    if (!config || config.id === undefined) {
      throw new Error('Invalid "config" parameter');
    }

    if (typeof config.id !== "string") {
      throw new Error('Invalid "id" parameter');
    }

    let id = config.id.trim();

    if (!id.length) {
      throw new Error('Invalid "id" parameter');
    }

    this.id = id;

    if (config.sku) {
      this.sku = config.sku.trim();
    }

    if (config.categoryId) {
      this.categoryId = config.categoryId.trim();
    }

    if (config.name) {
      this.name = config.name.trim();
    }

    if (config.brand) {
      this.brand = config.brand.trim();
    }

    if (config.price) {
      if (!Number.isFinite(config.price) || config.price < 0) {
        config.price = 0;
      }
      this.price = +config.price.toFixed(2);
    }

    if (config.priceBase) {
      if (!Number.isFinite(config.priceBase) || config.priceBase < 0) {
        config.priceBase = 0;
      }
      this.price = +config.priceBase.toFixed(2);
    }

    if (config.url) {
      this.url = config.url.trim();
    }

    if (config.image) {
      this.image = config.image.trim();
    }

    if (config.rating) {
      if (Number.isInteger(config.rating) && config.rating >= 0) {
        this.rating = +config.rating.toFixed();
      }
    }

    if (config.quantity) {
      if (Number.isInteger(config.quantity) && config.quantity > 0) {
        this.quantity = +config.quantity.toFixed();
      }
    }

    if (config.cartId) {
      if (Number.isInteger(config.cartId) && config.cartId > 0) {
        this.cartId = +config.cartId.toFixed();
      }
    }

    if (config.colorId) {
      if (Number.isInteger(config.colorId) && config.colorId > 0) {
        this.colorId = +config.colorId.toFixed();
      }
    }

    if (config.patina) {
      this.patina = config.patina;
    }

    if (config.patinaColorId) {
      if (Number.isInteger(config.patinaColorId) && config.patinaColorId > 0) {
        this.patinaColorId = +config.patinaColorId.toFixed();
      }
    }

    if (config.variationId) {
      let variationId = config.variationId.trim();
      if (variationId.length) {
        this.variationId = variationId;
      }
    }
  }

  getId() {
    return this.id;
  }

  getCartId() {
    return this.cartId;
  }

  getName() {
    return this.name;
  }

  getBrand() {
    return this.brand;
  }

  getCategoryId() {
    return this.categoryId;
  }

  getImage(size = 400, ext = "jpg"): string {
    if (!this.image) {
      throw new Error("Image not found"); // @todo report
    }
    let image = this.image.replace("-400.", `-${size}.`);
    image = image.replace(".jpg", `.${ext}`);
    return image;
  }

  getPrice() {
    return this.price;
  }

  getQuantity() {
    return this.quantity ?? 1;
  }

  setQuantity(quantity: number) {
    if (Number.isInteger(quantity) && quantity > 0) {
      this.quantity = quantity;
    }
  }

  /**
   * Сравнивает два продукта
   * Два товара считаются равными, если у них совпадают поля:
   * id, цвет, наличие и цвет патины, id варианта товара
   *
   * @param  {Product} first  - 1-й товар для сравнения
   * @param  {Product} second - 2-й товар для сравнения
   * @return {boolean}
   */
  static compare(first: Product, second: Product) {
    if (
      first.id !== second.id ||
      first.colorId !== second.colorId ||
      first.patina !== second.patina ||
      first.patinaColorId !== second.patinaColorId ||
      first.variationId !== second.variationId
    ) {
      return false;
    }
    return true;
  }

  getSum(): number {
    return (this.quantity ?? 1) * (this.price ?? 0);
  }

  toJSON() {
    let json = Object.assign({}, this);
    if ("name" in json) json.name = json.name.replaceAll("'", "&amp;quot;");
    return json;
  }
}
