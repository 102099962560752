/**
 * @module plugins/index.mjs
 * @todo
 * - разделить js/css
 */
//import Swal from 'sweetalert2'
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

import Mmenu from "mmenu-js";
import "mmenu-js/dist/mmenu.css";

// import { Notyf } from 'notyf';
// import 'notyf/notyf.min.css';

import { mmenuOptions } from "./mmenu.mjs";

export {
  initMMenuPlugin,
  initMHeadPlugin,
  initScollTop,
  confirm,
  toast,
  initRateYoPlugin,
};

/**
 * Инициализация плагина MMenu.js
 *
 * @todo
 * - режим offCanvas?
 * @param  {String} lang - Язык приложения
 * @param  {String} url  - Базовый URL
 */
function initMMenuPlugin(lang, url) {
  const options = mmenuOptions(lang, url);

  const mobileMenu = new Mmenu(
    "#mobile-menu",
    {
      theme: "dark", //  "light", "light-contrast", "dark", "dark-contrast" "white", "white-contrast", "black" and "black-contrast".
      // slidingSubmenus: true,
      backButton: {
        close: true,
      },
      setSelected: {
        hover: true,
        //parent: true,
      },
      navbar: {
        add: false,
        title: "Menu",
        titleLink: "parent", //"parent", "anchor" or "none"
      } /**/,
      iconbar: {
        use: true,
        top: [
          "<a href='/'><span class='icon--mdi icon--mdi--home'></span></a>",
          `<a href="https://www.facebook.com/planetmebli/" target="_blank" rel="nofollow">
            <span class="icon--mdi icon--mdi--facebook"></span>
          </a>`,
          `<a href="https://www.instagram.com/planeta_mebliv/" target="_blank" rel="nofollow">
            <span class="icon--mdi icon--mdi--instagram"></span>
          </a>`,
        ],
        bottom: [
          `<a href="viber://pa?chatURI=planet-mebli" target="_blank" rel="nofollow" title="Viber">
            <div class="messenger-icon">
              <span class="icon--fa6-brands icon--fa6-brands--viber"></span>
            </div>
          </a>`,
          `<a href="https://t.me/planetmebli" target="_blank" rel="nofollow" title="Telegram">
            <div class="messenger-icon">
              <span class="icon--logos icon--logos--telegram"></span>
            </div>
          </a>`,
          `<a href="https://m.me/planetmebli" target="_blank" rel="nofollow" title="Facebook Messenger">
            <div class="messenger-icon">
              <span class="icon--logos icon--logos--messenger"></span>
            </div>
          </a>`,
          `<a href="https://wa.me/380986160981" target="_blank" rel="nofollow" title="Whatsapp">
            <div class="messenger-icon">
              <span class="icon--logos icon--logos--whatsapp-icon"></span>
            </div>
          </a>`,
        ],
      },
      navbars: [
        {
          position: "top",
          type: "tabs",
          content: [
            "<a href='#mm-1'>Меню</a>",
            `<a href='#panel-language'>
              <span class='icon--mdi icon--mdi--web'></span>
              ${lang.toUpperCase()}
            </a>`,
          ],
        },
        {
          position: "bottom",
          content: [
            `<a class='' href='${url}/favorites/'>
              <span class='icon--mdi icon--mdi--heart'></span>
              <span class='badge badge-primary favorite-count'>0</span>
            </a>`,
            `<a class='' href='${url}/cart/'>
              <span class='icon--mdi icon--mdi--cart'></span>
              <span class="badge badge-primary cart-count">0</span>
            </a>`,
            `<a href="" data-mdb-toggle="modal" data-mdb-target="#modal__login_register" title="" class="open-popup-link">
              <span class="icon--mdi icon--mdi--account"></span>
            </a>`,
            `<a class='' href='${url}/cabinet/'>
              <span class='icon--mdi icon--mdi--account'></span>
            </a>`,
          ],
        },
      ],
      hooks: {
        "openPanel:before": (panel) => {
          const nav = document.getElementById("mobile-menu");
          if (nav) nav.style.display = "flex";
        },
      },
    },
    {
      language: lang,
      offCanvas: {
        page: {
          //nodetype: "section",
          selector: "#page",
        },
      },
    }
  );
  const mobileMenuAPI = mobileMenu.API;

  /*mobileMenuAPI.bind("openPanel:before", (panel) => {
    console.log("Started opening panel: " + panel.id);
    const url = window.location.origin + window.location.pathname;
    console.log("url: " + url);
    const items = panel.querySelectorAll("a.mm-listitem__text");
    const current = Array.from(items).find((item) => item.href.trim() === url);
    console.log(current);
    if (current) {
      mobileMenuAPI.setSelected(current.parentElement);
      console.log("Set selected");
    }
  });*/

  /*$(function ($) {
    var $mmenu = $("#mobile-menu").mmenu(options, {
      clone: true,
    });
    window.APIMMenu = $mmenu.data("mmenu");
  });*/

  /**/ document.addEventListener("click", (event) => {
    let link = event.target.closest(".mm-navbar .open-popup-link");
    if (!link) return;
    mobileMenuAPI.close();
  });

  document.addEventListener("click", (event) => {
    let link = event.target.closest("a[data-lang]");
    if (!link) return;
    event.preventDefault();
    console.log(link);
    let lang = link.dataset.lang.toLowerCase();
    toggleLang(lang);
  });

  /**/
}

function toggleLang(lang = uk) {
  let url = window.location.origin;
  const urlPrefix = lang !== "uk" ? `/${lang}` : "";
  let start = window.location.origin.length;
  let end;
  if (window.location.href.includes("#")) {
    end = window.location.href.indexOf("#");
  }
  if (lang == "uk") start += 3;
  url += urlPrefix + window.location.href.slice(start, end);
  window.location.href = url;
}

/**
 * Инициализация плагина Mhead.js
 */
function initMHeadPlugin(selector) {
  document.addEventListener("DOMContentLoaded", () => {
    new Mhead(selector, {
      scroll: { hide: 200 },
    });
  });
}

/**
 * Прокрутка вверх страницы
 * @function initScollTop
 * @param {String} selector
 */
function initScollTop(selector) {
  function e() {
    var e = jQuery(selector);
    jQuery(window).scrollTop() > 0
      ? e.css("bottom", "81px")
      : e.css("bottom", "-100px");
  }
  if (jQuery(selector).length) {
    jQuery(selector).on("click", function (e) {
      e.preventDefault(),
        jQuery("body,html").stop(!0).animate(
          {
            scrollTop: 0,
          },
          600
        );
    }),
      e();
  }
  jQuery(window).scroll(function () {
    e();
  });
}

/**
 * Вывод диалога-подтверждения
 *
 * @todo unscroll.reset();unscroll();
 * @param  {string} options.text
 * @param  {string} options.confirmButtonText
 * @param  {string} options.cancelButtonText
 * @param  {function} options.confirmAction
 */
async function confirm({
  text,
  confirmButtonText,
  cancelButtonText,
  icon = "question",
  confirmAction = null,
}) {
  const result = await Swal.fire({
    //title: 'Error!',
    //iconColor
    text,
    icon,
    //iconHtml: '<span class="icon--mdi icon--mdi--cart-outline"></span>',
    confirmButtonText,
    showCancelButton: true,
    cancelButtonText,
    scrollbarPadding: true,
    confirmButtonColor: "#c75b39",
    cancelButtonColor: "#a6a6a6",
  });

  if (result.isConfirmed && confirmAction) {
    confirmAction();
  }
}

function toast(options) {
  let config = {
    toast: true,
    showConfirmButton: false,
    timer: 10000,
    timerProgressBar: true,
    position: "top-end",
  };

  config = Object.assign(config, options);
  return Swal.fire(config);

  /*Swal.fire({
    //title: 'Error!',
    //iconColor: '#d99984',
    //text,
    //icon: 'info',
    //iconHtml,
    //timer: 4000,
    //timerProgressBar: true,
    //confirmButtonText,
    //confirmButtonColor: '#c75b39',
    //showCancelButton: true,
    //cancelButtonText,
    //scrollbarPadding: true,
    //cancelButtonColor: '#a6a6a6',
  });*/
}

function initRateYoPlugin() {
  $(function () {
    $("#addreview__rating__rateyo").rateYo({
      starWidth: "24px",
      normalFill: "#d3d3d3",
      ratedFill: "#F39C12",
      rating: 5,
      halfStar: true,
      onChange: function (rating, rateYoInstance) {
        $(this)
          .next()
          .html(
            new Intl.NumberFormat("ru-RU", {
              minimumFractionDigits: 1,
            }).format(rating)
          );
        $("#addreview__rating").val(rating);
      },
    });

    $("#reviewRate")
      .rateYo()
      .on("rateyo.set", function (e, data) {});
  });
}
