/**
 * Auth module
 * @module module/Auth
 */
import { sendRequest } from "../functions/sendRequest.ts";

export class Auth {
  static api = {
    login: "/api/v1/auth/login/",
    social: "/api/v1/auth/social/",
    register: "/api/v1/auth/register/",
    logout: "/api/v1/auth/logout/",
    requestReset: "/api/v1/auth/request-reset/",
    reset: "/api/v1/auth/reset/",
    delete: "/api/v1/auth/delete/",
  };

  constructor(lang) {
    this.setLang(lang);
  }

  getLang() {
    return this._lang;
  }

  /**
   * Устанавливает язык для отправки запросов
   * @todo
   * - ['ru', 'uk'] - вынести в константу
   * @param {[type]} lang [description]
   */
  setLang(lang) {
    if (!lang || typeof lang !== "string" || !["ru", "uk"].includes(lang)) {
      throw new Error("Invalid 'lang' parameter");
    }

    this._lang = lang;
  }

  /**
   * Вход в систему
   * @todo
   * - обработка ошибок:
   *   - ошибка парсинга JSON
   *   - ошибка соединения с сервером
   * @param  {FormData} formData - Данные для входа
   * @return {Object}
   */
  async login(formData) {
    if (!formData instanceof FormData) {
      throw new Error("Invalid 'formData' parameter");
    }
    const result = await sendRequest(Auth.api.login, {
      method: "POST",
      body: formData,
      searchParams: { lang: this.getLang() },
    });
    return result;
  }

  /**
   * Выход из системы
   * @return {boolean}
   */
  async logout() {
    let result = await sendRequest(Auth.api.logout);
    return result;
  }

  /**
   * Регистрация нового пользователя
   * @param  {FormData} formData - данные для регистрации
   * @return {Object}
   */
  async register(formData) {
    if (!formData instanceof FormData) {
      throw new Error(
        "Параметр должен быть типа FormData. Передан " + typeof formData
      );
    }

    const result = await sendRequest(Auth.api.register, {
      method: "POST",
      body: formData,
      searchParams: { lang: this.getLang() },
    });

    return result;
  }

  /**
   * Запрос на сброс пароля
   */
  async requestReset(formData) {
    if (!formData instanceof FormData) {
      throw new Error(
        "Параметр должен быть типа FormData. Передан " + typeof formData
      );
    }

    const result = await sendRequest(Auth.api.requestReset, {
      method: "POST",
      body: formData,
      searchParams: { lang: this.getLang() },
    });
    return result;
    console.log(result);
  }

  /**
   * Сброс пароля
   */
  async reset(formData) {
    if (!formData instanceof FormData) {
      throw new Error(
        "Параметр должен быть типа FormData. Передан " + typeof formData
      );
    }

    const result = await sendRequest(Auth.api.reset, {
      method: "POST",
      body: formData,
    });
    console.log(result);
  }

  /**
   * Аутентификация через социальные сети
   */
  async social(user) {
    const result = await sendRequest(Auth.api.social, {
      method: "POST",
      json: user,
      searchParams: { lang: this.getLang() },
    });

    if (result === null) {
      // @todo что делать
      return;
    }
    return result;
  }
}
