/**
 * @module forms/mask.mjs
 */

// import {MaskitoOptions} from '@maskito/core';

export {
  maskitoPhoneOptions
};

const maskitoPhoneOptions = {
    mask: [
        '+',
        '3',
        '8',
        '0',
        ' ',
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
    ],
};