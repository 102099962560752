/**
 * @module plugins/mmoptions.mjs
 */

/**
 * Возвращает объект конфигурации плагина MMenu.js
 * @param  {String} lang - Язык приложения
 * @param  {String} url  - Базовый URL
 */
export function mmenuOptions(lang, url) {
  let userName = shop?.user?.name ?? "";
  return {
    language: lang,
    transitionDuration: 400,
    offCanvas: {
      pageNodetype: "body",
    },
    navbar: {
      title: "Меню",
    },
    backButton: true,
    extensions: [
      "pagedim-black",
      "theme-dark",
      "fx-menu-slide",
      "shadow-page",
      "fx-listitems-fade",
      "border-full",
    ],
    navbars: [
      {
        position: "top",
      },
      {
        position: "bottom",
        content: [
          `<a href="${url}/favorites/" rel="nofollow">
          <span class="icon--mdi icon--mdi--heart"></span>
          <span class="badge badge-primary favorite-count">0</span>
        </a>`,
          `<a href="${url}/cart/" rel="nofollow">
          <span class="icon--mdi icon--mdi--cart"></span>
          <span class="badge badge-primary cart-count">0</span>
        </a>`,
          `<a href="" data-mdb-toggle="modal" data-mdb-target="#modal__login_register" title="" class="open-popup-link">
          <span class="icon--mdi icon--mdi--account"></span>
        </a>`,
          `<a href="${url}/cabinet/" title="" class="">
          <span class="icon--mdi icon--mdi--account"></span><span class="current-username">${userName}</span>
        </a>`,
        ],
      },
    ],
  };
}
