/**
 * @module auth/eventListeners.mjs
 */
import { Auth } from "../modules/Auth.mjs";
import {
  userLogin,
  userLogout,
  userRegister,
  userRequestReset,
  userResetPassword,
  goToRegisterTab,
  goToRequestResetForm,
  goToLoginForm,
} from "./functions.mjs";

export {
  addGuestAuthListeners,
  removeGuestAuthListenes,
  addUserAuthListeners,
  removeUserAuthListeners,
};

/**
 * Регистрация слушателей событий для неавторизованных пользователей.
 * Все события относятся к модальному окну вход/регистрация
 */
function addGuestAuthListeners() {
  // EventListener: Вход пользователя
  const loginUserBtn = document.getElementById("btn__user_login");
  loginUserBtn.addEventListener("click", userLogin);

  // EventListener: Регистрация пользователя
  const registerUserBtn = document.getElementById("btn__user_register");
  registerUserBtn.addEventListener("click", userRegister);

  // EventListener: Запрос на сброс пароля
  const requestResetBtn = document.getElementById("btn__requestreset__send");
  requestResetBtn.addEventListener("click", userRequestReset);

  /**
   * EventListener: Сброс пароля
   * @todo
   * - на отдельную страницу
   */
  // Подключение валидации Parsley
  //window.resetPasswordForm = $('#resetPasswordForm').parsley();

  // const resetPasswordBtn = document.getElementById('reset_btn');
  // resetPasswordBtn.addEventListener('click', userResetPassword);

  /**
   * EventListeners: События в окне Вход/Логин
   */
  // ссылка "регистрация"
  let selector = "div#modal__login_register .register-link";
  let registerLink = document.querySelector(selector);
  registerLink.addEventListener("click", goToRegisterTab);

  // ссылка "пароль утерян"
  selector = "div#modal__login_register .requestreset-link";
  let requestResetLink = document.querySelector(selector);
  requestResetLink.addEventListener("click", goToRequestResetForm);

  // ссылка "войти"
  selector = "div#modal__login_register .login-link";
  let loginLink = document.querySelector(selector);
  loginLink.addEventListener("click", goToLoginForm);
}

/**
 * [removeGuestAuthListenes description]
 * @todo
 * - когда и как отключать валидацию Parsley
 */
function removeGuestAuthListenes() {
  // EventListener: Вход пользователя
  const loginUserBtn = document.getElementById("btn__user_login");
  loginUserBtn.removeEventListener("click", userLogin);

  // EventListener: Регистрация пользователя
  const registerUserBtn = document.getElementById("btn__user_register");
  registerUserBtn.removeEventListener("click", userRegister);

  // EventListener: Запрос на сброс пароля
  const requestResetBtn = document.getElementById("btn__requestreset__send");
  requestResetBtn.removeEventListener("click", userRequestReset);

  /**
   * EventListener: Сброс пароля
   * @todo
   * - на отдельную страницу
   */
  // const resetPasswordBtn = document.getElementById('reset_btn');
  // resetPasswordBtn.removeEventListener('click', userResetPassword);

  /**
   * EventListener: события в окне Вход/Логин
   */
  // ссылка "регистрация"
  let selector = "div#modal__login_register .register-link";
  let registerLink = document.querySelector(selector);
  registerLink.removeEventListener("click", goToRegisterTab);

  // ссылка "пароль утерян"
  selector = "div#modal__login_register .requestreset-link";
  let requestResetLink = document.querySelector(selector);
  requestResetLink.removeEventListener("click", goToRequestResetForm);

  // ссылка "войти"
  selector = "div#modal__login_register .login-link";
  let loginLink = document.querySelector(selector);
  loginLink.removeEventListener("click", goToLoginForm);
}

function addUserAuthListeners() {
  // EventListener: Выход пользователя
  const logoutButtons = document.querySelectorAll(".btn__user_logout");
  logoutButtons.forEach((button) => {
    button.addEventListener("click", userLogout);
  });
}

function removeUserAuthListeners() {
  // EventListener: Выход пользователя
  const logoutButtons = document.querySelectorAll(".btn__user_logout");
  logoutButtons.forEach((button) => {
    button.removeEventListener("click", userLogout);
  });
}
