import { Product } from "../modules/Product";
import type { App } from "../modules/App";
import type { Item } from "./Item";
import type { EcommerceEvent, EcommerceValueEvent } from "./events";
/**
 * @module dataLayer/DataLayer.ts
 */
export { DataLayer };

class DataLayer {
  static ADD_PAYMENT_INFO_EVENT = "add_payment_info";
  static ADD_SHIPPING_INFO_EVENT = "add_shipping_info";
  static ADD_TO_CART_EVENT = "add_to_cart";
  static ADD_TO_WISHLIST_EVENT = "add_to_wishlist";
  static BEGIN_CHECKOUT_EVENT = "begin_checkout";
  static PURCHASE_EVENT = "purchase";
  static REFUND_EVENT = "refund";
  static REMOVE_FROM_CART_EVENT = "remove_from_cart";
  static SELECT_ITEM_EVENT = "select_item";
  static SELECT_PROMOTION_EVENT = "select_promotion";
  static VIEW_CART_EVENT = "view_cart";
  static VIEW_ITEM_EVENT = "view_item";
  static VIEW_ITEM_LIST_EVENT = "view_item_list";
  static VIEW_PROMOTION_EVENT = "view_promotion";

  dataLayer: Array<object>;
  shop: App;

  constructor(shop: App) {
    this.shop = shop;
    if (window.dataLayer === undefined) {
      window.dataLayer = new Array();
    }
    this.dataLayer = window.dataLayer;
  }

  pushEvent(config: any) {
    if (typeof config === "object" && config !== null) {
      let data: object;

      if (config.ecommerce !== undefined) {
        data = this.makeEcommerceEvent(config);
      } else {
        if (config.value !== undefined && config.currency === undefined) {
          config.currency = this.shop.getCurrency();
        }
        data = config;
      }
      if (this.shop.user.isLogged) {
        data.user_id = this.shop.user.id;
      }
      this.push(data);
    }
  }

  protected push(event: object) {
    this.dataLayer.push({ ecommerce: null });
    this.dataLayer.push(event);
    //console.clear();
    console.log("event", event);
  }

  protected makeEcommerceEvent(data: any): EcommerceEvent {
    if (data.ecommerce.items !== undefined) {
      data.ecommerce.items = this.productToItems(data.ecommerce.items);
      this.setValueCurrency(data);
    }
    return data;
  }

  protected productToItems(products: Product[]): Item[] {
    let items = products.map((value) => {
      if (!(value instanceof Product)) {
        return value;
      }
      let item: Item = {};

      item.item_id = value.getId();
      item.item_name = value.getName();
      //item.affiliation = value.affiliation;
      //item.coupon = value.coupon;
      //item.discount = value.discount;
      //item.creative_slot = value.creativeSlot;
      //item.creative_name = value.creativeName;
      //item.index = value.index;
      // item.item_brand = value.getBrand();

      // item_category2: value.,
      // item_category3: value,
      // item_category4: value.,
      // item_category5: value.,
      item.item_category = value.getCategoryId();
      //item.item_list_id = value.listId;
      //item.item_list_name = value.listName;
      //item.item_variant = value.variant;
      //item.location_id = value.locationId;
      item.price = value.getPrice();
      //item.promotion_id = value.promotionId;
      //item.promotion_name = value.promotionName;
      item.quantity = value.getQuantity();
      return item;
    });
    return items;
  }

  protected setValueCurrency(config: EcommerceValueEvent): EcommerceValueEvent {
    let ecommerce = config.ecommerce;

    if (ecommerce.items !== undefined) {
      if (ecommerce.items.length) {
        let value = ecommerce.items.reduce(
          (total: number, item: Item) =>
            total + (item.quantity ?? 1) * (item.price ?? 0),
          0
        );

        ecommerce.value = value;
      } else {
        delete ecommerce.items;
      }
    }

    if (ecommerce.value && !ecommerce.currency) {
      ecommerce.currency = this.shop.getCurrency();
    }

    return config;
  }
}

declare global {
  interface Window {
    FB: any; // Extend the window object with the 'FB' property
    dataLayer: Array<object> | undefined;
  }
}
