import { Auth } from "./Auth.mjs";
import { CartList } from "./CartList";
import { DataLayer } from "../dataLayer/DataLayer";
import { FavoriteList } from "./FavoriteList";
import { Product } from "./Product";
import { sendRequest } from "../functions/sendRequest";
import { User } from "./User";
import { ViewedList } from "./ViewedList";
/**
 * App module
 * @module app
 */

/**
 * Главный класс приложения
 * @property {string}       lang     - Язык приложения
 * @property {string}       baseURL  - Базовый URL
 * @property {string}       currency - Валюта
 * @property {User}         user     - Текущий пользователь
 * @property {FavoriteList} favorite - Избранные товары
 * @property {ViewedList}   viewed   - Просмотренные товары
 * @todo
 * - изменить способ инициализации объекта App - через запрос к серверу
 * - [now] проставить нормальные значения на лимиты
 */
export class App {
  static EVENT_AFTER_USER_INIT = "eAppAfterUserInit";
  static LANGS = ["ru", "uk"];
  static api = {
    config: "/ajax/config/",
    products: "/api/v1/products/",
  };

  lang: string;
  protected sourceLang;
  protected baseUrl: string;
  protected urlPrefix: string;
  protected currency: string;
  user: User;
  favorite: FavoriteList;
  viewed: ViewedList;
  cart: CartList;
  dataLayer: DataLayer;
  auth: Auth;
  messages: {};

  constructor({
    lang,
    user,
    favorites,
    cart,
    viewed,
  }: {
    lang: string;
    user: User;
    favorites: string[] | null;
    cart: object | null;
    viewed: string[] | null;
  }) {
    this.lang = lang;
    this.sourceLang = "uk";
    this.baseUrl = window.location.origin;
    this.urlPrefix = this.lang !== this.sourceLang ? `/${this.lang}` : "";
    this.currency = "UAH";
    this.messages = {};
    this.dataLayer = new DataLayer(this);
    this.auth = new Auth(this.lang);

    this.setTranslations(this.lang);
    this.initValidatorTranslations(this.lang);
    this.init({ user, cart, favorites, viewed });
    //JSON.parse(document.getElementById("app-config").textContent);
    /*this.loadUserConfig().then((result) => {
      if (result) {
        this.init(result);
      }
    });*/
  }

  /**
   * Инициализация приложения данными пользователя
   *
   * @param  {object}         user     - Текущий пользователь
   * @param  {object|null}    cart     - Корзина
   * @param  {string[]|null}  favorite - Избранное
   * @param  {string[]|null}  viewed   - Просмотренные товары
   * @return {void}
   */
  init({
    user,
    cart,
    favorites,
    viewed,
  }: {
    user: User;
    favorites: string[] | null;
    cart: object | null;
    viewed: string[] | null;
  }) {
    this.user = new User(user);
    this.cart = new CartList({ items: cart });
    this.favorite = new FavoriteList({ items: favorites });

    if (!this.user.isLogged) {
      ViewedList.loadLocal().then((items) => {
        this.viewed = new ViewedList({ items, user: this.user });
      });
    } else {
      this.viewed = new ViewedList({ items: viewed, user: this.user });
    }

    document.dispatchEvent(new CustomEvent(App.EVENT_AFTER_USER_INIT));
  }

  getLang(): string {
    return this.lang;
  }

  setLang(lang: string) {
    if (!(typeof lang === "string" || App.LANGS.includes(lang))) {
      throw new Error("Invalid 'lang' parameter");
    }
    this.lang = lang;
  }

  getBaseUrl(prefix = true): string {
    let url = this.baseUrl;
    if (prefix) url += this.urlPrefix;
    return url;
  }

  getCurrency() {
    return this.currency;
  }

  async setTranslations(lang: string) {
    let messages;
    if (lang === "ru") {
      messages = await import("../translations/messages-ru.json");
    } else if (lang === "uk") {
      messages = await import("../translations/messages-uk.json");
    } else {
      throw new Error("Передан неподерживаемый язык - " + lang);
    }
    this.messages = messages.default;
  }

  /**
   * Инициализация переводов Parsley.js
   * @param {string} lang - Язык приложения
   */
  async initValidatorTranslations(lang: string) {
    let mod;

    try {
      if (lang === "ru") {
        mod = await import("../translations/parsley-ru.js");
      } else if (lang === "uk") {
        mod = await import("../translations/parsley-uk.js");
      } else {
        throw new Error("Передан неподдерживаемый язык - " + lang);
      }
      Parsley.addMessages(lang, mod.messages);
      Parsley.setLocale(lang);
    } catch (e) {
      // @todo report()
      console.error(e);
    }
  }

  /**
   * Получение текущего пользователя и пользовательских списков товаров:
   * корзина, избранное, просмотренные товары, заказы
   * @todo
   * - добавить список заказов в ответ
   * @return {object} Объект конфигурации
   */
  protected async loadUserConfig() {
    return await sendRequest(App.api.config, {
      searchParams: {
        lang: this.getLang(),
      },
    });
  }

  static async loadProducts(
    ids: string[] | null,
    lang: string
  ): Promise<Product[] | null> {
    if (!Array.isArray(ids)) throw new Error("Invalid 'ids' parameter");
    if (typeof lang !== "string") throw new Error("Invalid 'lang' parameter");

    const result = await sendRequest(App.api.products, {
      method: "POST",
      json: { ids },
      searchParams: { lang },
    });

    if (result === null) return [];

    if (!Array.isArray(result)) throw new Error("Некорректный ответ сервера");

    const products: Product[] = [];

    for (let config of result) {
      let product: Product;
      try {
        product = new Product(config);
        products.push(product);
      } catch (e) {
        console.error("Invalid product configuration");
      }
    }
    return products;
  }
}
