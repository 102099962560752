import { UserConfig } from "./intefeces/UserConfig";
/**
 * User module
 * @module module/user
 */
export class User {
  static readonly UUID_LENGTH = 36;
  protected _id: number = 0;
  protected _uuid: string = "";
  protected _name?: string = "";
  protected _surname?: string = "";
  protected _patronymic?: string = "";
  protected _email?: string = "";
  protected _phone?: string = "";
  protected _region?: string = "";
  protected _city?: string = "";
  protected _address?: string = "";
  protected _isSubscribed?: boolean = false;

  constructor(config: UserConfig) {
    this.init(config);
  }

  init(config: UserConfig) {
    if (typeof config !== "object") {
      throw new Error("Invalid 'config' parameter");
    }

    this.id = config.id;
    this.uuid = config.uuid;
    this.name = config.name;
    this.surname = config.surname;
    this.patronymic = config.patronymic;
    this.email = config.email;
    this.phone = config.phone;
    this.region = config.region;
    this.city = config.city;
    this.address = config.address;
    this.isSubscribed = config.subscribe;
  }

  get id() {
    return this._id;
  }

  set id(id) {
    if (!Number.isInteger(id) || id < 0) {
      throw new Error("Invalid 'id' parameter");
    }
    this._id = id;
  }

  get uuid() {
    return this._uuid;
  }

  set uuid(uuid) {
    if (typeof uuid !== "string" || uuid.length !== User.UUID_LENGTH) {
      throw new Error("Invalid 'uuid' parameter");
    }
    this._uuid = uuid;
  }

  get name() {
    return this._name;
  }

  set name(name) {
    name ??= "";
    if (typeof name !== "string") {
      throw new Error("Invalid 'name' parameter");
    }
    this._name = name.trim();
  }

  get surname() {
    return this._surname;
  }

  set surname(surname) {
    surname ??= "";
    if (typeof surname !== "string") {
      throw new Error("Invalid 'surname' parameter");
    }
    this._surname = surname.trim();
  }

  get patronymic() {
    return this._patronymic;
  }

  set patronymic(patronymic) {
    patronymic ??= "";
    if (typeof patronymic !== "string") {
      throw new Error("Invalid 'patronymic' parameter");
    }
    this._patronymic = patronymic.trim();
  }

  get email() {
    return this._email;
  }

  set email(email) {
    email ??= "";
    if (typeof email !== "string") {
      throw new Error("Invalid 'email' parameter");
    }
    this._email = email.trim();
  }

  get phone() {
    return this._phone;
  }

  set phone(phone) {
    phone ??= "";
    if (typeof phone !== "string") {
      throw new Error("Invalid 'phone' parameter");
    }
    this._phone = phone.trim();
  }

  get region() {
    return this._region;
  }

  set region(region) {
    region ??= "";
    if (typeof region !== "string") {
      throw new Error("Invalid 'region' parameter");
    }
    this._region = region.trim();
  }

  get city() {
    return this._city;
  }

  set city(city) {
    city ??= "";
    if (typeof city !== "string") {
      throw new Error("Invalid 'city' parameter");
    }
    this._city = city.trim();
  }

  get address() {
    return this._city;
  }

  set address(address) {
    address ??= "";
    if (typeof address !== "string") {
      throw new Error("Invalid 'city' parameter");
    }
    this._address = address.trim();
  }

  get isSubscribed() {
    return this._isSubscribed;
  }

  set isSubscribed(isSubscribed) {
    isSubscribed ??= false;
    if (typeof isSubscribed !== "boolean") {
      throw new Error("Invalid 'isSubscribed' parameter");
    }
    this._isSubscribed = isSubscribed;
  }

  get isLogged() {
    return Boolean(this._id);
  }
}
