import localForage from "localforage";
import { App } from "./App";
import { ItemList } from "./ItemList";
import { Product } from "./Product";
import { ProductCard } from "../layers/productCard.js";
import { sendRequest } from "../functions/sendRequest";
import type { User } from "./User";
/**
 * ViewedList module
 * @module modules/ViewedList
 * @todo
 * - отображение - в обратном порядке
 */

/**
 * Класс списка просмотренных товаров
 */
export class ViewedList extends ItemList {
  static EVENT_AFTER_INIT = "eViewedAfterInit";
  static STORAGE_KEY = "plm_viewed_1.1";

  static api = {
    url: "/api/v1/viewed/",
    save: { method: "PUT" },
    products: { method: "GET", url: "/api/v1/products/viewed/" },
  };

  protected static VERSION = "1.1";
  protected user: User;

  /**
   * Создание экземпляра
   * @constructor
   * @param {object} config - Объект конфигурации
   */
  constructor({ user, items }: { user: User; items: string[] | null }) {
    super({
      type: "viewed",
      limit: 11,
      items,
      counterClass: "viewed-count",
    });

    this.user = user;

    setTimeout(() => {
      document.dispatchEvent(
        new CustomEvent(ViewedList.EVENT_AFTER_INIT, { detail: { list: this } })
      );
    });
  }

  /**
   * Добавляет новый ID товара в список
   * @todo
   * - изменить способ хранеиния items
   * @param {string}   id            - ID товара
   * @param {boolean}  dispatchEvent - Генерировать событие
   */
  add(id: string, dispatchEvent: boolean = true): boolean {
    if (typeof id !== "string") {
      throw new Error("Invalid 'id' parameter");
    }
    if (typeof dispatchEvent !== "boolean") {
      throw new Error("Invalid 'dispatchEvent' parameter");
    }

    this.items.delete(id);

    let tmp = [...this.items];

    if (this.size() >= this.getLimit()) {
      tmp.reverse();
      tmp.length = this.getLimit() - 1;
      tmp.reverse();
    }
    tmp.push([id, null]);
    this.items = new Map(tmp);

    if (dispatchEvent) {
      document.dispatchEvent(
        new CustomEvent(ItemList.EVENT_AFTER_UPDATE, {
          detail: { list: this },
        })
      );
      this.doAdd(id);
    }
    return true;
  }

  /**
   * Загружает IDs просмотренных товаров из LocalStorage
   * @returns {string[]|null}
   */
  static async loadLocal(): Promise<string[] | null> {
    const key = ViewedList.STORAGE_KEY;
    let viewed: string[] | null = null;

    try {
      let saved = (await localForage.getItem(key)) as string | null;
      if (saved === null) return null;

      if (typeof saved !== "string") throw new Error("Invalid data format");

      viewed = JSON.parse(saved);

      if (!(viewed || Array.isArray(viewed))) {
        throw new Error("Invalid data format");
      }
    } catch (e) {
      console.error(e);
    }
    if (viewed === null) return null;
    return Array.isArray(viewed) ? viewed : null;
  }

  /**
   * Сохраняет IDs просмотренных товаров
   */
  protected async doAdd(id: string): Promise<void> {
    if (!this.user.isLogged) {
      this.saveLocal();
      return;
    }

    if (typeof id !== "string") {
      throw new Error("Invalid 'id' parameter");
    }
    sendRequest(ViewedList.api.url, {
      method: "PUT",
      json: {
        uuid: this.user.uuid,
        id,
      },
    });
  }

  /**
   * Сохраняет IDs просмотренных товаров в LocalStorage
   */
  protected async saveLocal() {
    try {
      await localForage.setItem(
        ViewedList.STORAGE_KEY,
        JSON.stringify(this.getItemIds())
      );
    } catch (e) {
      console.error(e);
    }
  }

  clearLocal() {
    try {
      let result = localForage.removeItem(ViewedList.STORAGE_KEY);
      console.log("result", result);
    } catch (e) {
      console.error(e);
    }
  }

  saveRemote() {
    sendRequest(ViewedList.api.url, {
      method: "POST",
      json: {
        uuid: this.user.uuid,
        ids: this.getItemIds(),
      },
    });
  }

  /**
   * Загружает просмотренные товары
   * @todo
   * - проверить:
   *   порядок в котором товары приходят - задавать через запрос?
   *   формат массива
   * - прелоадеры для карточек товаров
   */
  async loadProducts(lang: string) {
    if (!lang || typeof lang !== "string") {
      throw new Error("Invalid 'lang' parameter");
    }

    if (!this.user.isLogged) {
      return await App.loadProducts(this.getItemIds(), lang);
    }

    let products: Product[] = [];

    const result = await sendRequest(ViewedList.api.products.url, {
      searchParams: {
        uuid: this.user.uuid,
        lang,
      },
    });

    if (result === null) return [];
    if (!Array.isArray(result)) throw new Error("Некорректный ответ сервера");

    for (let config of result) {
      let product: Product;
      try {
        product = new Product(config);
        products.push(product);
      } catch (e) {
        console.error("Invalid product configuration");
      }
    }
    return products;
  }

  /**
   * [getProductCards description]
   * @todo
   * - вынести
   * - wrapClasses = "col-12 col-sm-6 col-md-4 col-lg-3 p-3"
   * @param  {string} wrapClasses [description]
   * @return {[type]}             [description]
   */
  getProductCards(
    products: Product[],
    wrapClasses = "col-12 col-sm-6 col-md-4 col-lg-3 p-3"
  ) {
    let cardList: string[] = [],
      labels = shop.messages.label,
      polishes = shop.messages.polish;

    products.forEach((product) => {
      if (product instanceof Product) {
        // костыль
        let temp = new ProductCard(
          product,
          labels,
          polishes,
          wrapClasses
        ).render();
        cardList.push(temp);
      }
    });

    return cardList.join("");
  }

  /**
   * [getSwiper description]
   * @todo
   * - вынести
   * @return {string}
   */
  getSwiper(content: string): string {
    let titleViewed = shop.messages["title_viewed"];
    let start = `<section class="viewed-section">
    <div class="container">
        <div class="row">
            <div class="card col-12 p-0">
                <div class="card-header">
                    <h5 class="m-0">${titleViewed}</h5>
                </div>
                <div class="card-body swiper-container viewed-carousel">
                    <div class="swiper-wrapper">`;
    let finish = `</div>
                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>
                <div class="swiper-pagination"></div>
            </div>
            <div class="card-footer"> 
            </div>
        </div>
      </div>
    </div>
  </section>`;
    return start + content + finish;
  }
}
