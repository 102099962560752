import { addFormProgress, delFormProgress } from "./helpers.mjs";
import { sendRequest } from "../functions/sendRequest.ts";
import { Product } from "../modules/Product.ts";
import { toast } from "../plugins/index.mjs";

export { sendCallbackForm, sendReviewForm, addFormProgress, delFormProgress };

/**
 * Отправка формы "Заказать звонок"
 */
function sendCallbackForm(event) {
  event.preventDefault();
  // Подключение валидации Parsley
  const callBackForm = $("#callbackForm").parsley();
  callBackForm.whenValidate().then(async () => {
    let form = document.forms.callbackForm,
      modal = form.closest(".modal"),
      formData = new FormData(form);
    formData.append("url", location.href);
    addFormProgress(form);

    const result = await sendRequest("/ajax/callbackForm/", {
      method: "POST",
      body: formData,
    });

    delFormProgress(form);

    if (result && !result.error) {
      toast({
        // cofigured
        title: shop.messages["thank_you"],
        text: shop.messages["request_success"],
        icon: "success",
      });
      $(modal).modal("hide");

      const eventObject = {
        event: "request_callback",
      };

      const selector = `.product-details.product-info`;
      const card = document.querySelector(selector);

      if (card) {
        let productDetail = JSON.parse(card.dataset.detail);
        const product = new Product(productDetail);
        eventObject.value = product.getPrice();
      }

      shop.dataLayer.pushEvent(eventObject);
    } else {
      toast({
        // cofigured
        title: shop.messages["sorry"],
        text: result?.message ?? shop.messages["request_error"],
        icon: "error",
      });
    }
    form.reset();
  });
}

/**
 * Отправка отзыва
 * @todo
 * - добавить валидацию что б не было пустых отзывов
 */
async function sendReviewForm(event) {
  event.preventDefault();
  console.log("function sendReviewForm()");
  let form = document.forms.form__addreview,
    modal = form.closest(".modal"),
    url = "/api/v1/reviews/?lang=" + shop.getLang(),
    formData = new FormData(form);
  formData.append("url", location.href);
  $.each($("#addreview__files")[0].files, function (i, file) {
    formData.append(i, file);
  });
  addFormProgress(form);

  const result = await sendRequest(url, { method: "POST", body: formData });

  delFormProgress(form);

  if (result && !result.error) {
    toast({
      // cofigured
      title: shop.messages["thank_you"],
      text: shop.messages["reviewAdd"],
      icon: "success",
    });
  } else {
    toast({
      // cofigured
      title: shop.messages["sorry"],
      text: shop.messages["error"],
      icon: "error",
    });
  }
  $("#addreviewModal").modal("hide");
  form.reset();
}
