/**
 * Auth functions module
 * @module auth/functions.mjs
 */
import { Auth } from "../modules/Auth.mjs";
import { User } from "../modules/User.ts";
import { addFormProgress, delFormProgress } from "../forms/functions.mjs";

import { toast } from "../plugins/index.mjs";

import {
  removeGuestAuthListenes,
  addUserAuthListeners,
  removeUserAuthListeners,
} from "./eventListener.mjs";

export {
  userRegister,
  userLogin,
  userLogout,
  userRequestReset,
  userResetPassword,
  goToRegisterTab,
  goToRequestResetForm,
  goToLoginForm,
  resetAuthForms,
};

/**
 * Регистрация нового пользователя
 */
function userRegister(event) {
  event.preventDefault();
  const form = document.forms.form_register;
  const formRegister = $(form).parsley();

  formRegister.whenValidate().then(async () => {
    let formData = new FormData(form);

    addFormProgress(form);
    let result = await shop.auth.register(formData);
    delFormProgress(form);

    if (result === null || result.error) {
      form.password.value = "";
      form.repassword.value = "";
      toast({
        // cofigured
        title: shop.messages["error01"],
        text: result?.message ?? shop.messages["request_error"],
        icon: "error",
      });
      return;
    } else {
      toast({
        // cofigured
        //title: shop.messages["error01"],
        text: result?.message /* ?? shop.messages["request_error"]*/,
        icon: "success",
      });
    }

    resetAuthForms();
    $("#modal__login_register").modal("hide");

    // @todo автоматическая обработка viewed
    shop.user.init(result.user);
    shop.viewed.saveRemote();
    shop.viewed.clearLocal();

    shop.dataLayer.pushEvent({
      event: "sign_up",
      method: result.method,
      user_id: result.user.id,
    });

    afterLogin(result);
  });
}

/**
 * Вход пользователя
 * @todo
 * - синхронизировать валидации Parsley и PHPAuth
 */
async function userLogin(event) {
  event.preventDefault();
  const form = document.forms.form_login;
  const formLogin = $(form).parsley();

  formLogin.whenValidate().then(async () => {
    let formData = new FormData(form),
      messages = shop.messages;

    addFormProgress(form);
    let result = await shop.auth.login(formData);
    delFormProgress(form);

    if (result && !result.error) {
      resetAuthForms();
      $("#modal__login_register").modal("hide");
      // form.reset();
      // document.forms.form_register.reset();
      shop.user = new User(result.user);
      shop.cart.setItems(result.cart, false);
      shop.viewed.setItems(result.viewed, false);
      shop.favorite.setItems(result.favorite);
      afterLogin(result);
    } else {
      form.password.value = "";
      toast({
        // cofigured
        title: shop.messages["error01"],
        text: result?.message ?? shop.messages["request_error"],
        icon: "error",
      });
    }
  });
}

/**
 * [afterLogin description]
 * @todo
 * - после логина и, соответственно, смены UUID теряются Просмотренные товары
 * - как объединить с уже существующими?
 * - не пересоздавать пользователя
 * - при регистрации это событие иде в dl первым
 * @param  {Event} event Объект события
 */
function afterLogin(result) {
  document.body.classList.replace("user-loggedout", "user-loggedin");

  shop.dataLayer.pushEvent({
    event: "login",
    method: result.method,
    user_id: shop.user.id,
  });

  document.querySelectorAll(".current-username").forEach((item) => {
    item.innerHTML = shop.user.name;
  });

  if (document.querySelector("body").classList.contains("page-order")) {
    document.querySelector(".tabs__login_register_checkout").remove();
    let el;
    el = document.getElementById("personal_info__name");
    el.value = shop.user.name;
    el.classList.add("active");
    el = document.getElementById("personal_info__surname");
    el.value = shop.user.surname;
    el.classList.add("active");
    el = document.getElementById("personal_info__phone");
    el.value = shop.user.phone;
    el.classList.add("active");
  }
  removeGuestAuthListenes();
  addUserAuthListeners();
}

/**
 * Выход пользователя
 * @param  {[type]} event [description]
 * @return {[type]}       [description]
 */
async function userLogout(event) {
  event.preventDefault();
  const result = await shop.auth.logout();

  if (result && !result.error) {
    removeUserAuthListeners();
    shop.user.init(result.user);
    shop.favorite.setItems(null);
    shop.cart.setItems(null);
    shop.viewed.setItems(null);

    document.body.classList.replace("user-loggedin", "user-loggedout");
    document.querySelectorAll(".current-username").forEach((item) => {
      item.innerHTML = "";
    });

    if (document.body.classList.contains("page-cabinet")) {
    }
    window.location.href = shop.getBaseUrl();
  } else {
    toast({
      // cofigured
      title: shop.messages["error01"],
      text: result?.message ?? shop.messages["request_error"],
      icon: "error",
    });
  }
}

/**
 * Запрос на сброс пароля
 */
function userRequestReset(event) {
  console.log("Запрос на сброс пароля");
  event.preventDefault();
  const form = document.forms.form__requestreset;
  const resetForm = $(form).parsley();

  resetForm.whenValidate().then(async () => {
    console.log("Запрос на сброс пароля2");
    let modal = form.closest(".modal"),
      fData = new FormData(form);

    addFormProgress(form);
    let result = await shop.auth.requestReset(fData);
    delFormProgress(form);

    if (!result.error) {
      resetAuthForms();
      toast({
        // cofigured
        text: result.message,
        icon: "success",
      });
      //form.reset();
      if (modal) $("#modal__login_register").modal("hide");
    } else {
      toast({
        // cofigured
        title: shop.messages["error01"],
        text: result.message,
        icon: "error",
      });
    }
  });
}

/**
 * Сброс пароля
 * @todo
 * - вынести на отдельную страницу
 */
function userResetPassword(event) {
  event.preventDefault();
  window.resetPasswordForm.whenValidate().then(async () => {
    let form = btn.closest("form"),
      fData = new FormData(document.forms.resetPasswordForm);
    addFormProgress(form);
    let response = await shop.auth.reset(fData);
    delFormProgress(form);
    if (!response.error) {
      //resetAuthForms()
      //form.reset();
    }

    toast({
      // cofigured
      title: shop.messages["error01"],
      text: result.message,
      icon: "error",
    });
  });
}

/**
 * Обработчики перехода по ссылкам в окне вход/регистрация
 * @param  {Event} event - Объект события
 */
function goToRegisterTab(event) {
  event.preventDefault();
  $('a[href="#tab-users-register"]').tab("show");
}

function goToRequestResetForm(event) {
  event.preventDefault();
  document.forms.form_login.style.display = "none";
  document.forms.form__requestreset.style.display = "block";
}

function goToLoginForm(event) {
  event.preventDefault();
  document.forms.form_login.style.display = "block";
  document.forms.form__requestreset.style.display = "none";
}

/**
 * Сброс состояния форм входа/регистрации в модальном окне
 * @todo
 * - написать отдельную функцию resetForm()
 */
function resetAuthForms() {
  const forms = document.forms;
  $('a[href="#tab-users-login"]').tab("show");
  forms.form_login.style.display = "block";
  forms.form__requestreset.style.display = "none";

  forms.form_login.reset();
  forms.form_register.reset();
  forms.form__requestreset.reset();

  $(forms.form_login).parsley().reset();
  $(forms.form_register).parsley().reset();
  $(forms.form_login).parsley().reset();
}
