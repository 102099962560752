/**
 * @module common.js
 */
import { App } from "./modules/App.ts";
import { ItemList } from "./modules/ItemList";
import { updateCardButtons } from "./functions.js";
import { sendCallbackForm } from "./forms/functions.mjs";
import { setPhoneMask } from "./forms/helpers.mjs";
import { resetAuthForms } from "./auth/functions.mjs";
import {
  addGuestAuthListeners,
  removeGuestAuthListenes,
  addUserAuthListeners,
} from "./auth/eventListener.mjs";

import {
  initializeGoogle,
  renderGoogleButtons,
  displayGoogleOneTap,
  statusChangeCallback,
} from "./auth/socials.mjs";

import {
  initMMenuPlugin,
  initMHeadPlugin,
  initScollTop,
} from "./plugins/index.mjs";

import "../css/icons.css";

import { Product } from "./modules/Product.ts";

const configId = "app-config";
const content = document.getElementById(configId)?.textContent;
if (!content) throw new Error("Configuration not found");
let appConfig = null;
try {
  appConfig = JSON.parse(content);
} catch (e) {
  console.error(e);
}

console.log(appConfig);
const shop = new App(appConfig);
window.shop = shop;

// Форматирование валюты
window.currencyFormatter = new Intl.NumberFormat(shop.getLang(), {
  localeMatcher: "best fit",
  //style: "currency",
  //currency: "UAH",
  maximumFractionDigits: 0,
});
document.addEventListener("click", (event) => {
  const phoneLink = event.target.closest("a.phone-link");
  if (!phoneLink) return;

  const eventObject = {
    event: "click_phone_number",
    operator: phoneLink.dataset.operator,
  };

  const selector = `.product-details.product-info`;
  const card = document.querySelector(selector);

  if (card) {
    try {
      let productDetail = JSON.parse(card.dataset.detail);
      const product = new Product(productDetail);
      eventObject.value = product.getPrice();
    } catch (e) {
      if (e instanceof SyntaxError) {
        console.error(e);
      }
    }
  }

  shop.dataLayer.pushEvent(eventObject);
});
console.log(shop.user.isLogged);
if (!shop.user.isLogged) {
  // пользователь не авторизирован

  const loginRegisterModal = document.getElementById("modal__login_register");
  // addGuestAuthListeners
  //loginRegisterModal.addEventListener('show.bs.modal',  function(e) {
  addGuestAuthListeners();
  //});
  // removeGuestAuthListenes
  loginRegisterModal.addEventListener("hide.bs.modal", function (e) {
    //removeGuestAuthListenes();
    resetAuthForms();
  });

  if (initializeGoogle() !== null) {
    renderGoogleButtons(".g_id_signin2");
    displayGoogleOneTap();
  }

  window.statusChangeCallback = statusChangeCallback;
} else {
  // пользователь авторизирован
  addUserAuthListeners();
}

/**
 * EventListener: ItemList.EVENT_AFTER_UPDATE
 * Изменение состояния списков Корзина, Избранное, Просмотренные.
 */
document.addEventListener(ItemList.EVENT_AFTER_UPDATE, (event) => {
  const list = event.detail.list;
  ItemList.updateCounters(list.getCounterClass(), list.size());
  if (list.getType() === "cart" || list.getType() === "favorite") {
    updateCardButtons(list);
  }
});

// Прикрепляет слушатель к форме "Заказать звонок",
// инициализирует маску для ввода номера телефона
const callbackModal = document.getElementById("callbackFormModal");
callbackModal.addEventListener("show.bs.modal", function (e) {
  // EventListener: "Заказать звонок"
  const callBackSendBtn = document.getElementById("callbackFormSend");
  callBackSendBtn.addEventListener("click", sendCallbackForm);
  /**
   * @todo
   * - сохранение инстаннса
   * - удалять при скрытии формы
   * @type {Maskito}
   */
  const callBackForm = document.forms.callbackForm;
  const masked = setPhoneMask(callBackForm);
});

// Скрытие формы "Заказать звонок"
callbackModal.addEventListener("hide.bs.modal", function (e) {
  document.forms.callbackForm.reset();
  $(document.forms.callbackForm).parsley().reset();
  const callBackSendBtn = document.getElementById("callbackFormSend");
  callBackSendBtn.removeEventListener("click", sendCallbackForm);
});

/**
 * Инициализация плагинов
 */
initScollTop(".scroll-to-top");
initMMenuPlugin(shop.getLang(), shop.getBaseUrl());
initMHeadPlugin("#header");
