/**
 * @module pageIndex/functions.mjs
 */

export { initReviewSlider };

/**
 * Инициализация слайдера отзывов
 * @param  {string} selector - CSS селектор контейнера
 */
function initReviewSlider(selector) {
  if (typeof selector !== "string") {
    throw new Error('"selector" должен быть экземпляром String');
  }

  const reviewsSwiper = new Swiper(selector, {
    loop: true,
    direction: "horizontal",
    speed: 2000,
    autoHeight: true,
    slidesPerView: 1,
    spaceBetween: 0,
    preloadImages: false,
    lazy: true,
    namespace: {
      slideClass: "reviews-swiper-slide",
    },
    autoplay: {
      delay: 1700,
      disableOnInteraction: false,
    },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
      dynamicBullets: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

  const container = document.querySelector(selector);
  container.addEventListener("mouseover", () => {
    reviewsSwiper.autoplay.stop();
  });

  container.addEventListener("mouseout", () => {
    reviewsSwiper.autoplay.start();
  });
}
