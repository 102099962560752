/**
 * @module auth/socials.mjs
 */

export {
  initializeGoogle,
  renderGoogleButtons,
  displayGoogleOneTap,
  statusChangeCallback,
};

/**
 * [initializeGoogle description]
 * @todo
 * - возвращаемое значение
 * @return {[type]} [description]
 */
function initializeGoogle() {
  try {
    google.accounts.id.initialize({
      client_id:
        "859956494182-3oa48qh2cobqhb42fik4401cj5bhhgdu.apps.googleusercontent.com",
      callback: handleCredentialResponse,
    });
  } catch (e) {
    console.warn(e.message);
    return null;
  }
}

/**
 * Display the Google One Tap dialog
 * @todo
 * - добавить вызов
 */
function displayGoogleOneTap() {
  try {
    google.accounts.id.prompt();
  } catch (e) {
    if (e.message == "google is not defined") {
      console.warn(e);
    } else {
      throw e;
    }
  }
}

/**
 * Рендеринг кнопки Google SignIn
 * @todo
 * - добавить вызов
 * @param  {String} selector - CSS селектор кнопок
 */
function renderGoogleButtons(selector) {
  const buttons = document.querySelectorAll(selector);
  if (!buttons.length) {
    return;
  }

  const buttonOptions = {
    type: "standard",
    shape: "rectangular",
    theme: "outline",
    text: "$ {button.text}",
    size: "large",
    logo_alignment: "left",
    locale: shop.getLang(),
    width: 300,
  };

  try {
    buttons.forEach((button) => {
      google.accounts.id.renderButton(button, buttonOptions);
    });
  } catch (e) {
    console.warn(e);
  }
}

/**
 * Сallback Google-Auth
 * @param  {[type]} response [description]
 * @return {[type]}          [description]
 */
async function handleCredentialResponse(response) {
  console.log("response", response);
  const responsePayload = decodeJwtResponse(response.credential);
  console.log("responsePayload", responsePayload);
  const profile = responsePayload;
  // console.log(profile);
  profile.social = "google";

  let result = await shop.auth.social(profile);

  let modal = document.getElementById("modal__login_register");
  if (modal.classList.contains("show")) {
    $("#modal__login_register").modal("hide");
  }

  if (!result.error) {
    shop.dataLayer.pushEvent({
      event: "sign_up",
      method: "google",
      user_id: result.user.id,
    });
  }
}

/**
 * [decodeJwtResponse description]
 * @param  {[type]} token [description]
 * @return {[type]}       [description]
 */
function decodeJwtResponse(token) {
  // console.log("token:", token);
  let base64Url = token.split(".")[1];
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  let jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
}

/**
 * Called with the results from FB.getLoginStatus()
 *
 * @function statusChangeCallback
 * @param  {[type]} response [description]
 * @return {[type]}          [description]
 */
function statusChangeCallback(response) {
  // The current login status of the person.
  console.log(response);
  if (response.status === "connected") {
    // Logged into your webpage and Facebook.
    handleFacebookResponse();
  } else {
    // Not logged into your webpage or we are unable to tell.
    console.log("Please log into this webpage.");
  }
}

/**
 * @function handleFacebookResponse
 * Testing Graph API after login.
 * See statusChangeCallback() for when this call is made.
 *
 * @return {[type]} [description]
 */
function handleFacebookResponse() {
  console.log("Welcome!  Fetching your information.... ");
  const fields = [
    "id",
    "first_name",
    "name",
    "last_name",
    "middle_name",
    "name_format",
    "email",
    "picture",
    "short_name",
  ];

  FB.api(
    "/me",
    {
      fields,
    },
    async function (response) {
      response.social = "facebook";
      let result = await shop.auth.social(response);

      if (!result.error) {
        shop.dataLayer.pushEvent({
          event: "sign_up",
          method: "facebook",
          user_id: result.user.id,
        });
      }

      console.log(result);
      console.log("Successful login for: " + result.name);
    }
  );
}
