import { ItemList } from "./ItemList";
import { Product } from "./Product";
import { sendRequest } from "../functions/sendRequest";
/**
 * CartList module
 * @module modules/CartList
 * @todo
 */

export class CartList extends ItemList {
  static EVENT_AFTER_ADD = "eCartAfterAdd";
  static EVENT_UPDATE_PRODUCT_QUANTITY = "eUpdateProductQuantity";
  static EVENT_AFTER_UPDATE_QUANTITY = "eAfterUpdateProductQuantity";

  static api = {
    url: "/api/v1/cart/",
    add: { method: "POST" },
    remove: { method: "DELETE" },
    quantity: { method: "PATCH" },
  };

  items: Map<string, Product[]>;

  constructor({ items }: { items: object | null }) {
    if (typeof items !== "object") {
      console.log(items);
      console.log(typeof items);
      throw new Error("Invalid 'items' parameter");
    }
    super({ items, type: "cart", limit: 3, counterClass: "cart-count" });
  }

  /**
   * Добавляет новый товар в Корзину
   * @param  {Product}  product        Добавляемый товар
   * @param  {boolean}  dispatchEvent  Генерировать событие
   * @return {Product | null}
   */
  add(product: Product, dispatchEvent: boolean = true): boolean {
    if (!(product instanceof Product)) {
      throw new Error("Invalid 'product' parameter");
    }

    if (typeof dispatchEvent !== "boolean") {
      throw new Error('Invalid "dispatchEvent" parameter');
    }
    let result: boolean = false;
    // @todo hasId()?
    if (!this.hasId(product.getId())) {
      // нет такого ID
      if (this.size() < this.getLimit()) {
        this.items.set(product.getId(), [product]);
        result = true;
      }
    } else {
      // есть такой ID
      const existing = this.find(product);
      if (existing) {
        // есть такой продукт
        //const cartId = existing.getCartId();
        // const quantity = existing.getQuantity() + product.getQuantity();
        if (existing.getCartId()) {
          //result = this.updateQuantity(cartId, quantity, dispatchEvent);
          existing.setQuantity(existing.getQuantity() + product.getQuantity());
          result = true;
        } else {
          // @todo если cartId еще не присвоен
          result = 0;
        }
      } else {
        // нет такого продукта
        if (this.size() < this.getLimit()) {
          const items = this.items.get(product.getId());
          if (items) items.push(product);
          result = true;
        }
      }
    }

    if (dispatchEvent && result) {
      document.dispatchEvent(
        new CustomEvent(ItemList.EVENT_AFTER_UPDATE, {
          detail: { list: this },
        })
      );

      this.doAdd(product);
    }

    return result;
  }

  /**
   * Установка элементов списка
   * @todo
   * - используется ли параметр reset?
   * - нужен ли setTimeout?
   * @param {object|null}  items - Элементы списка
   * @param {Boolean}      reset - Сброс существующих элементов
   */
  setItems(items: object | null, reset = true): void {
    // console.log('items', items)
    if (typeof items !== "object")
      throw new TypeError("Invalid 'items' parameter");

    if (typeof reset !== "boolean") {
      throw new TypeError("Invalid 'reset' parameter");
    }

    if (reset) this.items = new Map();

    for (let id in items) {
      for (let config of items[id]) {
        this.add(new Product(config), false);
      }
    }

    // Нулевой таймаут для минимальной задержки при вызове из конструктора
    setTimeout(() => {
      document.dispatchEvent(
        new CustomEvent(ItemList.EVENT_AFTER_UPDATE, { detail: { list: this } })
      );
    });
  }

  /**
   * Удаление из Корзины
   * @todo
   * - возвращаемое значение
   * @function remove
   * @param  {string} cartId ID записи в Корзине
   * @return {[type]}        [description]
   */
  remove(cartId: number): Product | undefined {
    if (!Number.isInteger(cartId) || cartId < 1) {
      throw new Error("Invalid 'cartId' parameter");
    }

    for (let [key, value] of this.items.entries()) {
      if (!Array.isArray(value)) {
        throw new Error("Значение должно быть массивом");
      }

      let i = value.findIndex((item) => item.getCartId() === cartId);
      if (!~i) continue;

      let removed = value.splice(i, 1);
      if (!value.length) this.items.delete(key);

      this.doRemove(cartId);

      document.dispatchEvent(
        new CustomEvent(ItemList.EVENT_AFTER_UPDATE, { detail: { list: this } })
      );

      return removed[0];
    }
    return;
  }

  /**
   * Обновление количества товаров
   * @param  {number}  cartId        ID товара в Корзине
   * @param  {number}  quantity      Количество
   * @param  {boolean} dispatchEvent Генерировать событие
   * @return {boolean}
   */
  updateQuantity(
    cartId: number,
    quantity: number,
    dispatchEvent: boolean = true
  ): boolean {
    if (!Number.isInteger(cartId) || cartId < 1) {
      throw new Error("Invalid 'cartId' parameter");
    }

    if (!Number.isInteger(quantity) || quantity < 1) {
      throw new Error("Invalid 'quantity' parameter");
    }

    let result = false,
      item: Product | null = null,
      difference: number = 0;

    for (let value of this.items.values()) {
      if (!Array.isArray(value)) {
        throw new Error("Значение должно быть массивом");
      }
      let i = value.findIndex((item) => item.getCartId() === cartId);
      if (!~i) continue;

      const exists = value[i];
      if (exists) {
        difference = quantity - exists.getQuantity();
        if (difference === 0) return result;
        exists.setQuantity(quantity);
        item = exists;
        result = true;
      }
    }

    if (dispatchEvent && result) {
      document.dispatchEvent(
        new CustomEvent(ItemList.EVENT_AFTER_UPDATE, { detail: { list: this } })
      );

      const event = difference > 0 ? "add_to_cart" : "remove_from_cart";
      const product = new Product(item);
      product.setQuantity(Math.abs(difference));

      shop.dataLayer.pushEvent({
        event,
        ecommerce: { items: [product] },
      });
      this.doUpdateQuantity(cartId, quantity);
    }

    return result;
  }

  /**
   * Возвращает количество товаров в Корзине
   * @return {number}
   */
  size(): number {
    let size = 0;
    for (let value of this.items.values()) {
      if (!Array.isArray(value))
        throw new Error("Значение должно быть массивом");

      size += value.length;
    }
    return size;
  }

  /**
   * Возвращает сумму по всем товарам в Корзине
   * @return {number}
   */
  getTotal(): number {
    let total = 0;
    for (let value of this.items.values()) {
      if (!Array.isArray(value))
        throw new Error("Значение должно быть массивом");

      for (let item of value) {
        total += item.getSum();
      }
    }
    return total;
  }

  /**
   * Проверяет вхождение товара в список
   * @param  {Product}  product Объект товара
   * @return {boolean}
   */
  protected has(product: Product): boolean {
    if (!(product instanceof Product)) {
      throw new Error("Invalid 'product' parameter");
    }

    const items = this.items.get(product.getId());
    if (!items) return false;
    let i = items.findIndex((item: Product) => Product.compare(item, product));
    return !!~i;
  }

  /**
   * Поиск идентичного товара в списке
   * @param  {Product}            product Искомый товар
   * @return {Product|undefined}  Ссылка на товар в списке или null
   */
  protected find(product: Product): Product | undefined {
    if (!(product instanceof Product)) {
      throw new Error("Invalid 'product' parameter");
    }

    let items = this.items.get(product.getId());
    if (items === undefined) return undefined;

    return items.find((item) => Product.compare(item, product));
  }

  /**
   * @todo
   * - обработать ошибки сервера: откат/недопускать несовпадения списков клиент-сервер
   * - что метод должен возвращать?
   * - переименовать метод
   * @param  {Product} product Объект товара
   */
  protected async doAdd(product: Product) {
    if (!(product instanceof Product)) {
      throw new Error("Invalid 'product' parameter");
    }

    const result = await sendRequest(CartList.api.url, {
      method: "POST",
      json: product,
      searchParams: { lang: shop.getLang() },
    });

    if (result === null) {
      // @todo ??? что дальше
      return;
    }

    let newItem = new Product(result.item),
      record = this.items.get(newItem.getId());

    if (record === undefined) throw new Error("Не найден ID записи");

    // замена временной заглушки на товар, полученный с сервера
    let i = record.findIndex((item) => Product.compare(newItem, item));
    if (!~i) throw new Error("Не найдена кофигурация для замены");
    let tmp = record[i];
    if (tmp) newItem.setQuantity(tmp.getQuantity());
    record.splice(i, 1, newItem);

    let dlItem = Object.assign({}, newItem);
    dlItem.quantity = product.getQuantity();
    shop.dataLayer.pushEvent({
      event: "add_to_cart",
      ecommerce: { items: [dlItem] },
    });
  }

  protected async doRemove(cartId: number) {
    if (!Number.isInteger(cartId) || cartId < 1) {
      throw new Error("Invalid 'cartId' parameter");
    }

    const result = await sendRequest(CartList.api.url, {
      method: "DELETE",
      searchParams: { id: cartId },
    });

    if (result === null) {
      // @todo ??? что дальше
      return;
    }
  }

  protected async doUpdateQuantity(cartId: number, quantity: number) {
    if (!Number.isInteger(cartId) || cartId < 1) {
      throw new Error("Invalid 'cartId' parameter");
    }

    if (!Number.isInteger(quantity) || quantity < 1) {
      throw new Error("Invalid 'quantity' parameter");
    }

    const result = await sendRequest(CartList.api.url, {
      method: "PATCH",
      json: {
        cartId,
        quantity,
      },
    });

    if (result === null) {
      // @todo ??? что дальше
      return;
    }
  }

  /**
   * Переключает состояние кнопки "в Корзину" в зависимости от вхождения
   * или отсутствия товара в списке. Если второй параметр не передан,
   * то меняет состояние кнопки на противоположное.
   * @todo
   * - static или вынести в функции
   * @param  {HTMLElement} card   Карточка товара
   * @param  {boolean}     status Указывает есть ли товар в списке
   */
  toggleButton(card: HTMLElement, status: boolean) {
    let force;
    const btn = card.querySelector('button[is="button-cart"]');
    if (!btn) return;

    const current = btn.hasAttribute("data-listed");

    if (status === undefined) {
      btn.toggleAttribute("data-listed");
    } else if (status && !current) {
      btn.setAttribute("data-listed", "");
    } else if (!status && current) {
      btn.removeAttribute("data-listed");
    }

    if (status !== undefined) force = !status;

    btn.classList.toggle("btn_cart_add", force);
    force = status;
    btn.classList.toggle("btn_cart_in", force);
  }
}
