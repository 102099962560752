import ky from "ky";
/**
 * Отправка/получение данных
 * @async
 * @function sendRequest
 * @param {string} resource
 * @param {object} options
 * @returns
 */

export async function sendRequest(resource: string, options: object = {}) {
  let defaultOptions = {
    // prefixUrl: "",
    mode: "same-origin",
    credentials: "same-origin",
    cache: "no-store",
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  options =
    options && typeof options === "object"
      ? Object.assign(defaultOptions, options)
      : defaultOptions;

  let response;
  let result;
  try {
    response = await ky(resource, options);

    if (!response.headers.has("Content-Length")) {
      result = response.status;
      return result;
    }

    const contentType = String(response.headers.get("Content-Type"));

    if (contentType.includes("application/json")) {
      result = await response.json();
    } else if (contentType.includes("text/plain")) {
      result = await response.text();
    } else {
      result = response;
    }
    return result;
  } catch (e) {
    if (e.name === "HTTPError" || e.name === "SyntaxError") {
      console.error(e);
      return null;
    } else {
      throw e;
    }
  }
}
