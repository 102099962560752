/**
 * ItemsList module
 * @module modules/ItemList
 * @todo
 * - класифицировать и создать нужные типы пользовательских ошибок
 * - универсальный перехватчик всех необработанных ошибок JS
 * - документация
 * - кеширование, статус и обновление _products
 * - один общий кеш для всех товаров
 * - метод reset(), Evevnt
 */

import type { Product } from "./Product";

/**
 * Базовый класс списка товаров
 * @property {string}  type - Тип списка
 * @property {string}  counterClass - DOM-класс счетчика списка
 * @property {number}  limit - Лимит количества товаров
 * @property {Map}     items - Список товаров
 */
export abstract class ItemList {
  static EVENT_AFTER_UPDATE = "eItemListAfterUpdate";

  static ERROR_ALREADY_LISTED = "The product is already listed";
  static ERROR_ITEM_NOT_FOUND = "Product not found";

  protected type: string;
  protected limit: number;
  protected counterClass: string;
  protected items: Map<string, Product | null>;

  /**
   * Создание экземпляра
   * @param  {object} config  Объект конфигурации
   */
  constructor({
    type,
    limit,
    counterClass,
    items,
  }: {
    type: string;
    limit: number;
    counterClass: string;
    items: string[] | null;
  }) {
    this.type = type;
    this.limit = limit;
    this.counterClass = counterClass;
    this.items = new Map();
    this.setItems(items);
  }

  abstract add(id: string, dispatchEvent: boolean): boolean;

  /**
   * Возвращает тип списка
   * @returns {string}
   */
  getType(): string {
    return this.type;
  }

  /**
   * Возвращает размер лимита
   * @returns {number}
   */
  getLimit(): number {
    return this.limit;
  }

  /**
   * Возвращает DOM-класс счетчика списка
   * @returns {string}
   */
  getCounterClass(): string {
    return this.counterClass;
  }

  /**
   * Установка элементов списка
   * @todo
   * - используется ли параметр reset?
   * - нужен ли setTimeout?
   * @param {Array|null}  items - Массив элементов списка ['ID1',...'IDN']
   * @param {boolean}     reset - Сброс существующих элементов
   */
  setItems(items: string[] | null, reset: boolean = true) {
    if (!Array.isArray(items) && items !== null) {
      // throw new TypeError('Invalid "items" parameter');
      console.log('Invalid "items" parameter:');
      console.log(items);
      this.items = new Map();
      return;
    }

    if (reset) this.items = new Map();

    if (items !== null && items.length) {
      for (let element of items) {
        if (typeof element === "string") {
          this.add(element, false);
        }
      }
    }

    // Нулевой таймаут для минимальной задержки при вызове из конструктора
    setTimeout(() => {
      document.dispatchEvent(
        new CustomEvent(ItemList.EVENT_AFTER_UPDATE, {
          detail: { list: this },
        })
      );
    });
  }

  /**
   * Получение IDs элементов списка
   * @return {string[]} массив элементов списка ['ID1',...'IDN']
   */
  getItemIds(): string[] {
    return Array.from(this.items.keys());
  }

  /**
   * Проверяет вхождение ID товара в список
   * @param  {string}  id - Идентификатор товара
   * @return {boolean}
   */
  hasId(id: string): boolean {
    if (typeof id !== "string") throw new Error("Invalid 'id' parameter");
    return this.items.has(id);
  }

  /**
   * Возвращает размер списка
   * @return {number}
   */
  size(): number {
    return this.items.size;
  }

  /**
   * Обновление счетчиков пользовательских списков
   * @todo:
   * - вынести и привязывать this?
   * @param className - DOM-класс счетчика
   * @param count - Количество товаров
   */
  static updateCounters(className: string, count: number) {
    if (typeof className !== "string")
      throw new Error("Invalid 'className' parameter");
    if (!Number.isFinite(count)) throw new Error("Invalid 'count' parameter");
    let counters = document.getElementsByClassName(className);
    for (let element of counters) {
      element.textContent = "" + count;
    }
  }
}
