/**
 * @module forms/helpers.mjs
 */
import { Maskito, /*MaskitoOptions*/ } from '@maskito/core';
import { maskitoPhoneOptions } from './mask.mjs';

export {
	setPhoneMask,
	addFormProgress,
	delFormProgress,
};

/**
 * Устанавливает маску номера телефона для поля формы
 * @todo
 * - Call it when the element is destroyed maskedInput.destroy();
 * - обработка нескольких полей
 * @param {HTMLElement} form - Форма с полем для маскировки
 */
function setPhoneMask(form) {
	if (!form instanceof HTMLElement) {
		throw new Error('"form" должен быть экземпляром HTMLElement');
	}
	const selector = 'input[type="tel"]';
	const element = form.querySelector(selector);
	if (!element) {	return;	}
	const maskedInput = new Maskito(element, maskitoPhoneOptions);
	return maskedInput;
}

/**
 * Добавление прелоадера формы
 * @function addFormProgress
 * @param {HTMLFormElement} form
 */
function addFormProgress(form) {
  let pleloader = `<div class="linear-activity"><div class="indeterminate"></div></div>`;
  form.insertAdjacentHTML('afterbegin', pleloader);
  form.querySelector('fieldset').setAttribute("disabled", "disabled");
}

/**
 * Удаление прелоадера формы
 * @function delFormProgress
 * @param {HTMLFormElement} form
 */
function delFormProgress(form) {
  form.querySelector('.linear-activity').remove();
  form.querySelector('fieldset').removeAttribute("disabled");
}