/**
 * Common functions module
 * @module functions
 */

export {
  hideProductLabels,
  showProductLabels,
  removeProductCard,
  escapeHtml,
  updateCardButtons,
  debounce,
};

/**
 * Обновление состояния кнопок Избранное и Корзина
 * @param {FavoriteList|CartList} list Список товаров
 */
function updateCardButtons(list) {
  const cards = document.querySelectorAll(".product-card");
  cards.forEach((card) => {
    let status = list.hasId(card.dataset.id);
    list.toggleButton(card, status);
  });
}

/**/ $(function () {
  $(".product-card").hover(hideProductLabels, showProductLabels);
});
/**
 * Скрывает метки товара при наведении
 * @function hideProductLabels
 */
function hideProductLabels() {
  let labels = $(this).find(".labels");
  setTimeout(() => {
    if ($(this).is(":hover")) {
      $(labels)
        .stop(true, true)
        .animate(
          {
            left: "-300px",
          },
          500,
          function () {
            $(labels).stop(true, true).css({
              opacity: 0,
              left: 0,
            });
          }
        );
    }
  }, 1000);
}

/**
 * Включает отображение меток товара при потере фокуса
 * @function showProductLabels
 */
function showProductLabels() {
  let labels = $(this).find(".labels");
  setTimeout(() => {
    $(labels).stop(true, true).animate(
      {
        opacity: 1,
      },
      500
    );
  }, 1500);
}

/**
 * Удаляет карточку товара при удалении его из списка
 * @function removeProductCard
 * @todo доработать
 * @param {Object}      list - Список товаров
 * @param {HTMLElement} card - HTML-элемент карточки
 */
function removeProductCard(list, card) {
  if (list.size()) {
    card.remove();
    return;
  }
  let message = shop.messages["nothing_added_list"];
  let html = `<blockquote class="blockquote empty-message text-center"><p>${message}</p></blockquote>`;
  // let listRow = card.parentElement;
  let listRow = document.querySelector(".row.row-" + list.getType());
  let headerRow = listRow.previousElementSibling;
  listRow.remove();
  headerRow.insertAdjacentHTML("afterend", html);
}

/**
 * Экранирование HTML
 * @function escapeHtml
 * @param  {string} string - Исходная строка
 * @return {string}
 */
function escapeHtml(string) {
  return String(string).replace(/[&<>"'\/]/g, function (s) {
    return entityMap[s];
  });
}

/**
 * @param {*} ctx The context
 * @param {function} func The function to execute after the debounce time
 * @param {number} delay The amount of time to wait
 * @return {function} The debounced function
 */
function debounce(context, func, delay) {
  let timeout;

  return (...args) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      func.apply(context, arguments);
    }, delay);
  };
}
